<template>
  <div class="patient__History">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-9
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.dailyProcessReport") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
          </div>
        </div>
        <div
          class="
            col-3
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            v-can="'specialties.create'"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}
          </el-button>
        </div>
      </div>
      <table class="table table-bordered table-hover" v-loading="loadingData">
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>

            <crm-th
              :column="columns.date"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.amount"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.settings"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              >
              </el-input>
            </th>

            <th v-if="columns.amount.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.date"
                :placeholder="columns.date.title"
              >
              </el-input>
            </th>

            <th v-if="columns.amount.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.amount"
                :placeholder="columns.amount.title"
              >
              </el-input>
            </th>

            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="express in list" :key="express.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ express.id }}</td>
            <td v-if="columns.date.show">{{ express.date }}</td>
            <td v-if="columns.amount.show">{{ express.amount }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="show(express)"
                >{{ $t("message.show_0") }}</el-button
              >
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="60%" :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer>
    <el-drawer
      title="Новый тег"
      :visible.sync="drawerShow"
      size="60%" :wrapperClosable="false"
      :drawer="drawerShow"
      @open="reopenUpdate = true"
      @close="reloadIfChanged"
    >
      <div>
        <crm-show
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerShow')"
        ></crm-show>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmShow from "./components/crm-show";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";

import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";

export default {
  mixins: [ form, list, drawer],
  components: {
    CrmCreate,
    CrmUpdate,
    CrmShow,
  },
  data() {
    return {
      loadingData: false,
      drawerCreate: false,
      selectedModel: {},
      reopenUpdate: false,
      drawerShow: false,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        show: {
          name: "show",
          status: false,
          component: "componentDrawerShow",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "dailyProcessReport/list",
      columns: "dailyProcessReport/columns",
      pagination: "dailyProcessReport/pagination",
      filter: "dailyProcessReport/filter",
      sort: "dailyProcessReport/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "dailyProcessReport/index",
      updateSort: "dailyProcessReport/updateSort",
      updateFilter: "dailyProcessReport/updateFilter",
      updateColumn: "dailyProcessReport/updateColumn",
      updatePagination: "dailyProcessReport/updatePagination",
      showModel: "dailyProcessReport/show",
      empty: "dailyProcessReport/empty",
      delete: "dailyProcessReport/destroy",
      refreshData: "dailyProcessReport/refreshData",
    }),
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    show(model) {
      this.showModel(model.id)
        .then((res) => {
          this.reopenUpdate = true;
          this.drawerShow = true;
          this.drawer.show.status = true;
        })
        .catch((err) => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>